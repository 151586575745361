<template>
    <div class="container-fluid flex-column" style="height:100vh">
        <div class="row-fluid" style="height:50px"></div>
        <div style="margin-top:40px" class="row-fluid p-3">
            <h1>GUIDE</h1>
        </div>
        <div class="row bg-white">
            <div style="margin-bottom:56px" class="p-5 w-100 text-center">
                <Intro/>
                <div v-if="!fetching">
                    <div class="w-100 d-flex flex-row justify-content-center">
                        <div style="max-width:1200px;" class="col-12">
                            <div v-if="tutorials_data != false">
                                <div style="max-width:1200px;" v-for="(tutorial) in tutorials_data" v-bind:key="tutorial.id">
                                    <div class="mt-4 col-12 p-2 shadow">
                                        <div class="row m-0">
                                            <div class="text-left d-flex flex-column justify-content-center col-10">
                                                <span class="">{{tutorial.title}}</span>
                                            </div>
                                            <div class="col-2 text-right">
                                                <router-link class="menu-link ml-2 text-light" :to="`/guide/${tutorial.slug}`"><i class="fab text-primary fa-readme fa-2x"></i></router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-5">
                                <h2 class="m-0 text-danger"><b>sembra che al momento non ci siano guide!</b></h2>
                                <p class="mt-3"><b>Ma non preoccuparti! Puoi sempre scriverci su <a href="https://telegram.hackintoshitalia.it" class="text-primary">Telegram</a> per chiederci supporto</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="mt-3 spinner-border text-info" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p class="text-info mt-3"><b>Attendi...<br> Le guide stanno arrivando.</b></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Intro from '../components/Intro.vue'
export default {
    name: 'Guide',
    metaInfo:{
        title: 'Hackintosh Italia',
        titleTemplate: '%s - Guide',
        meta:[
            {charset: 'utf-8'},
            {name: 'keywords', content:"Hackintosh, Italia, Hardware"},
            {name: 'description', content:'Qui trovete una lista di guide che possono aiutarvi nella creazione del vostro Hackintosh.'}
        ]
    },
    components: {
        Intro
    },
    data: function (){
        return {
            fetching: true,
            tutorials_data: '',
        }
    },
    beforeMount(){
        this.fetchTutorials();
    },
    methods:{
        async fetchTutorials(){
            const res = await fetch(`${this.$api_url}/api/tutorials.php`)
            if(res.status != 200){
                this.tutorials_data = false;
            }else{
                const json = await res.json();
                json.empty != undefined ? this.tutorials_data = false : 0;
                json.empty != undefined ?  this.fetching = false : 0;
                json.list != undefined ? this.showList(json) : 0;
            }
        },
        showList: function (_data){
            delete _data['list'];
            this.tutorials_data = _data;
            this.fetching = false;
        }
    }
}
</script>
<style>

</style>