<template>
    <div class="container-fluid flex-column" style="height:100vh">
        <div class="row-fluid" style="height:50px"></div>
        <div style="margin-top:40px" class="row-fluid p-3">
            <h1 class="text-uppercase">{{title}}</h1>
        </div>
        <div class="row bg-white">
            <div style="margin-bottom:56px" class="p-5 w-100 text-center">
                <div v-if="!fetching">
                    <div class="w-100 d-flex flex-row justify-content-center">
                        <div style="max-width:1200px;" class="col-12">
                            <div v-if="contents_data != false">
                                <div class="mt-4 col-12 p-2 shadow">
                                    <div style="max-width:1200px;" v-for="(content) in contents_data" v-bind:key="content.id">
                                        <div class="row m-0">
                                            <div style="word-wrap: break-word; max-width:100%;" class="tutorial_content text-left p-2" v-html="content.content"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="mt-5">
                                <h2 class="m-0 text-danger"><b>sembra che al momento non ci siano contenuti!</b></h2>
                                <p class="mt-3"><b>Ma non preoccuparti! Puoi sempre scriverci su <a href="https://telegram.hackintoshitalia.it" class="text-primary">Telegram</a> per chiederci supporto</b></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="mt-3 spinner-border text-info" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
                    <p class="text-info mt-3"><b>Attendi...<br> La guida sta arrivando.</b></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Guida',
    data: function (){
        return {
            fetching: true,
            contents_data: '',
        }
    },
    beforeMount(){
        this.fetchContents(this.$route.params.slug);
    },
    methods:{
        async fetchContents(slug){
            var formData = new FormData();
            formData.append('slug',slug);
            const res = await fetch(`${this.$api_url}/api/contents.php`,{
                method: "POST",
                body: formData,
                credentials: 'include'
            })
            if(res.status != 200){
                this.contents_data = false;
            }else{
                const json = await res.json();
                json.empty != undefined ? this.contents_data = false : 0;
                json.empty != undefined ?  this.fetching = false : 0;
                json.list != undefined ? this.showList(json) : 0;
            }
        },
        showList: function (_data){
            delete _data['list'];
            this.title = _data[0];
            delete _data[0];
            this.contents_data = _data;
            this.fetching = false;
        }
    }
}
</script>
<style>
    .tutorial_content img{
        max-width: 100%;
    }
    .tutorial_content h1, .tutorial_content h2{
        color: #000000;
    }
    .ql-align-center{
        text-align: center;
    }
    .ql-align-right{
        text-align: right;
    }
    .ql-align-justify{
        text-align: justify;
    }
    .ql-syntax{
        color:white !important;
        background-color: black !important;
        padding: 1em !important;
        word-wrap: break-word !important;
    }
</style>